<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Forms <span>Edit Form Message</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form v-slot="{ errors }" ref="edit-delivery-message-form">
                        <label for="notification" class="switch_option capsule_btn">
                            <h5>Notification?</h5>
                            <input type="checkbox" id="notification" v-model="form.delivery_notification_status" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-show="form.delivery_notification_status">
                            <div class="section_wpr mb-2">
                                <div class="edit_section">
                                    <sending-method v-model="form.notification_method" />
                                </div>
                            </div>
                            <div v-if="form.notification_method === 1 || form.notification_method === 3">
                                <div class="setting_wpr mb-4">
                                    <email-component v-model="emailContent" :errors="errors" :has-email-button="true" :handle-default-email="handleDefaultEmail" preview-module="forms" ref="email-component" />
                                </div>
                            </div>
                            <div v-if="form.notification_method === 2 || form.notification_method === 3">
                                <div class="setting_wpr">
                                    <sms-component v-model="form.sms_message" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" :mediaFieldName="form.media" preview-module="forms" ref="sms-component" />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment';
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Form Edit Delivery Message',

        data () {
            return {
                form: {
                    delivery_notification_status: 0,
                    notification_method: 1,
                    sms_message: '',
                    media: '',
                    survey_id: '',
                    title: '',
                },
                emailContent: {
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                    defaultHandler: true,
                },
            }
        },

        props: {
            modelValue: Boolean,
            activeForm: Object,
        },

        emit: ['update:modelValue'],

        components: {
            Form
        },

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.resetForm();
                }
            }
        },

        computed: mapState({
            loader: state => state.formModule.formUpdateLoader,
        }),

        methods: {
            ...mapActions({
                updateForm: 'formModule/updateForm'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form.delivery_notification_status    = vm.activeForm.delivery_notification_status ? 1 : 0;
                vm.form.notification_method             = vm.activeForm.survey_message.notification_method ? vm.activeForm.survey_message.notification_method : 3;
                vm.form.sms_message                     = vm.activeForm.survey_message.sms_message ? vm.activeForm.survey_message.sms_message : '';
                vm.form.media                           = vm.activeForm.survey_message.media ? vm.activeForm.survey_message.media : '';
                vm.form.survey_id                       = vm.activeForm.survey_message.survey_id ? vm.activeForm.survey_message.survey_id : '';
                vm.form.title                           = vm.activeForm.survey_message.title ? vm.activeForm.survey_message.title : '';
                vm.emailContent.subject                 = vm.activeForm.survey_message.email_subject ? vm.activeForm.survey_message.email_subject : '';
                vm.emailContent.email                   = vm.activeForm.survey_message.email_message ? vm.activeForm.survey_message.email_message : '';
                vm.emailContent.show_email_logo         = vm.activeForm.survey_message.show_email_logo ? vm.activeForm.survey_message.show_email_logo : 1;
                vm.emailContent.email_logo              = vm.activeForm.survey_message.page_image ? vm.activeForm.survey_message.page_image : '';
                vm.emailContent.is_signature            = vm.activeForm.survey_message.is_signature ? 1 : 0;
                vm.emailContent.signature_id            = vm.activeForm.survey_message.signature_id ? vm.activeForm.survey_message.signature_id : '';
                vm.emailContent.email_button_bg_color   = vm.activeForm.survey_message.email_button_bg_color,
                vm.emailContent.email_button_text_color = vm.activeForm.survey_message.email_button_text_color,
                vm.emailContent.module_id               = vm.activeForm.id;
            },

            handleDefaultEmail () {
                const vm = this;

                const email = `<p>Hi {{first_name}},</p>
                               <p>{{owner_first_name}} from {{company_name}} is requesting that you complete a form.</p>
                               <p>{{form_url_button}}</p>
                               <p><br>Sincerely</p>
                               <p>Team {{company_name}}</p>`;

                vm.emailContent.subject = 'A form is due';
                vm.emailContent.email   = email;
            },

            handleDefaultSms () {
                const vm = this;

                vm.form.sms_message = 'Hi {{first_name}}\r\n,{{owner_first_name}} from {{company_name}} is requesting that you complete a form.\r\n{{form_url_sms}}\r\nSincerely\r\nTeam {{company_name}}';
            },

            handleSubmitForm () {
                const vm  = this;

                const form = vm.$refs['edit-delivery-message-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleUpdateForm(form);
                    }
                })
            },

            handleUpdateForm (form) {
                const vm = this;

                vm.form.email_subject           = vm.emailContent.subject;
                vm.form.email_message           = vm.emailContent.email;
                vm.form.show_email_logo         = vm.emailContent.email_logo ? 1 : 0;
                vm.form.page_image              = vm.emailContent.email_logo;
                vm.form.is_signature            = vm.emailContent.is_signature;
                vm.form.signature_id            = vm.emailContent.signature_id;
                vm.form.survey_id               = vm.activeForm.id;
                vm.form.email_button_bg_color   = vm.emailContent.email_button_bg_color;
                vm.form.email_button_text_color = vm.emailContent.email_button_text_color;

                const params = {
                                    form_id: vm.activeForm.id,
                                    id: vm.activeForm.id,
                                    delivery_notification_status: vm.form.delivery_notification_status,
                                    survey_message: vm.form,
                                    survey_name: vm.activeForm.survey_name,
                                    show_email_logo: vm.form.show_email_logo,
                                    email_logo: vm.emailContent.email_logo,
                                    date_modified: moment().format("YYYY-MM-DD"),
                                };

                vm.updateForm(params).then((result) => {
                    if (result) {
                        Toastr.success('Form delivery message has been successfully updated.');

                        if (vm.$parent.$parent && vm.$parent.$parent.refreshForm) {
                            vm.$parent.$parent.refreshForm();
                        } else {
                            vm.$parent.refreshForm();
                        }

                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>
